<template>
  <div>
    <div class="my-3">
      <div class="text-lg">{{ t('notes') }}</div>
      <textarea
        v-model="notes"
        rows="3"
        class="w-full rounded bg-bg_color outline-none text-text_color p-1 border focus:border-text_color"
      />
    </div>
    <div class="flex justify-center">
      <SelaSubmitButton
        v-if="
          isDomain && store.active_package?.package?.support_whats_app_invoices
        "
        :title="t('apply_by', { by: t('whatsapp') })"
        type="button"
        width="w-max md:w-full"
        :loading="false"
        @click="goToWhatsAppMessage()"
      />

      <div v-else-if="!isDomain" class="flex justify-center">
        <SelaSubmitButton
          :title="t('order_now')"
          type="button"
          width="w-max md:w-full"
          icon="mdi mdi-check"
          :loading="loadingBuyBill"
          @click="orderBillNow()"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Store, Table } from '~/composables/useMenuModel'
const { chechIfStoreIsOpennning } = useBill()
const { getCodeForApiDeliveryMethod } = useCartMethods()

const {
  form: billForm,
  buyBill,
  resetForm,
  checkErrorBill,
  loadingBuyBill
} = useBill()

const notes = ref('')

const { t } = useI18n()
const props = defineProps<{
  store: Store
  selectedShippingWay?: ShippingWay
  branchId?: number
  itemsWithOutOffers: CartItem[]
  totalPrice: number
  tablePriceAdded: number
  allOffersWithItems: CartItem[][]
  countCostItems: {
    originalPrice: number
    discount: number
  }
  currency: {
    code: any
    currencyIsRtl: any
  }
  itemsWithOffers: object
  paymentImage: any
  selectedPayWays: {
    payType?: String
    payWayCard?: payWay
  }
  table?: Table
}>()
async function orderBillNow() {
  resetForm()
  billForm.bill_details = billDetails.value
  billForm.note = notes.value.trim()
  billForm.table_id = props.table?.table_id
  billForm.payment_image = props.paymentImage
  billForm.payment_office = props.selectedPayWays.payWayCard?.name
  billForm.agent_place_id = props.selectedShippingWay?.agentPlace?.id
  billForm.store_id = props.store?.id
  billForm.branch_id = props.branchId
  billForm.delivery_method = getCodeForApiDeliveryMethod(
    props.selectedShippingWay?.type
  )

  if (props.selectedPayWays) {
    if (props.selectedPayWays.payWayCard) {
      billForm.payment_type = 0
      billForm.payment_details = ` نظام الدفع : ${props.selectedPayWays?.payWayCard.name} - مالك الحساب : ${props.selectedPayWays?.payWayCard.account_owner_name} -  رقم التحويل : ${props.selectedPayWays.payWayCard.iban} `
    } else {
      billForm.payment_type = 1
    }
  }
  const isHasNotInitailError =
    checkErrorBill(
      props.store!,
      props.totalPrice,
      props.table,
      props.selectedPayWays,
      props.selectedShippingWay
    ) || false
  if (!isHasNotInitailError) {
    await buyBill()
  }
}
function openDialog(store?: Store) {
  emit('open-dialog', { store })
}
const emit = defineEmits(['open-dialog'])
const isDomain = useIsDomain()
const billDetails = computed(() => {
  const itemsWithOutOffers = [] as any
  const itemsWithOffers = [] as any

  props.allOffersWithItems.forEach((itemsPerOffer: CartItem[]) => {
    itemsPerOffer.forEach((item: CartItem) => {
      itemsWithOffers.push({
        quantity: item.quantity,
        material: { id: item.material.id, price: item.material.price },
        material_group_offer_id: item.material_group_offer_id,
        group_offer_uuid: item.group_offer_uuid,
        addons: item.addons?.length ? reduceAddons(item) : undefined,
        material_id: item.material.id
      })
    })
  })
  props.itemsWithOutOffers.forEach((item: CartItem) => {
    itemsWithOutOffers.push({
      quantity: item.quantity,
      material: { id: item.material.id, price: item.material.price },
      material_id: item.material.id,
      propertiesLookupValues: item.properties_lookup_values?.length ? reducePropertiesLookup(item) : undefined,
      addons: item.addons?.length ? reduceAddons(item) : undefined
    })
  })
  const allItems = [...itemsWithOutOffers, ...itemsWithOffers]
  return JSON.stringify(allItems)
})
function reducePropertiesLookup(item: CartItem) {
  const props = item.properties_lookup_values?.reduce((r: any, a) => {
    return [
      ...r,
      {
        property_definition_id: a.property_id || a.property_definition_id,
        value_id: a.value_id
      }
    ]
  }, [])

  return props?.length ? props : []
}
function reduceAddons(item: CartItem) {
  const props = item.addons?.reduce((r: any, a) => {
    return [
      ...r,
      {
        id: a.id,
        quantity: a.pivot?.quantity
      }
    ]
  }, [])

  return props?.length ? props : []
}
function goToWhatsAppMessage() {
  const isStoreOpening = props.store
    ? chechIfStoreIsOpennning(props.store)
    : true
  if (isStoreOpening) {
    useSentItemsCartByWhatsApp(
      props.itemsWithOutOffers,
      props.itemsWithOffers,
      props.store,
      props.totalPrice,
      notes.value,
      props.countCostItems,
      props.selectedPayWays,
      props.selectedShippingWay,
      props.tablePriceAdded,
      props.table,
      props.currency,
      openDialog,
      props.branchId
    )
  }
}
</script>
