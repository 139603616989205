<template>
  <div v-if="store?.price_visible" class="mb-1">
    <div class="text-lg text-center font-semibold md:hidden mt-2 mb-1">
      {{ t('bill') }}
    </div>
    <div class="rounded-dotted">
      <div class="sela-details-line">
        <div class="text-lg">{{ t('amount') }}</div>
        <div>
          {{ useStringPrice(countCostItems.originalPrice, currencyCode) }}
        </div>
      </div>

      <div class="sela-details-line">
        <div class="text-lg">{{ t('discount') }}</div>
        <div>{{ useStringPrice(countCostItems.discount, currencyCode) }}</div>
      </div>

      <div
        v-if="
          shippingWay?.deliveryCost != null &&
            shippingWay?.deliveryCost != undefined
        "
        class="sela-details-line"
      >
        <div class="text-lg">{{ t('shipping_cost') }}</div>
        <div>
          {{ useStringPrice(shippingWay.deliveryCost, currencyCode) }}
        </div>
      </div>
      <div v-if="tablePriceAdded" class="sela-details-line">
        <div class="text-lg">{{ t('table_cost') }}</div>
        <div>
          {{ useStringPrice(tablePriceAdded, currencyCode) }}
        </div>
      </div>
      <div
        class="flex justify-between pt-4 font-semibold text-text_color text-opacity-80"
      >
        <div class="text-lg">{{ t('total') }}</div>
        <div class="rounded-dotted dark">
          {{ useStringPrice(totalPrice, currencyCode) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Store } from '~/composables/useMenuModel'
const { t } = useI18n()
defineProps<{
  store?: Store
  shippingWay?: ShippingWay
  tablePriceAdded?: number
  totalPrice: number
  currencyCode: string
  countCostItems: {
    originalPrice: number
    discount: number
  }
}>()
</script>
