<template>
  <div v-if="cartItem.material.addons.length" :key="`addons-grid-${cartItem.material.id}`" class="flex justify-center flex-wrap px-1">
    <div
      v-for="(attribute, index) in cartItem.material.addons"
      :key="`attribute-item-${index}`"
      class="p-0.5 w-full sm:w-1/2 md:w-full lg:w-1/2 "
    >
      <div class="flex justify-between items-center bg-bg_color border border-text-200 rounded-md overflow-hidden" style="filter: brightness(.98)">
        <div class="flex-shrink-1 py-1 px-2 break-all">
          <div class="text-sm flex flex-wrap text-sela-light">
            <div class="font-medium ">
              {{ attribute.name }}
            </div>
            <div
              v-if="cartItem?.material.store?.price_visible"
              class="font-light l mx-1 bg-nav"
            >
              {{ attribute.price }}
            </div>
          </div>
        </div>
        <div
          v-if="cartItem?.material.store?.cart_visible"
          class="flex-shrink-0 flex  items-center text-text_color px-2 py-0.5"
        >
          <button
            :aria-label="t('increase_item', { name: t('quantity') })"
            class="p-1"
            :disabled="disableIncreaseItem(attribute.id)"
            @click="editQuantityAddonAttribue(attribute.id, '+')"
          >
            <v-icon
              icon="mdi mdi-plus-thick"
              :size="14"
              class="block"
              :class="{
                'opacity-50 cursor-not-allowed': disableIncreaseItem(attribute.id)
              }"
            />
          </button>
          <SelaLoadingIndicator
            v-if="loading[attribute.id]||false"
            :size="4"
            color="border-nav_color"
            class="text-nav_color h-full"
            :loading="loading[attribute.id]"
          />
          <div v-else class="px-2">
            {{ addonAttributeSelected[attribute.id].quantity }}
          </div>

          <button
            :aria-label="t('decrease_item', { name: t('quantity') })"
            class="p-1"
            :disabled="addonAttributeSelected[attribute.id].quantity <= 0"
            @click="editQuantityAddonAttribue(attribute.id, '-')"
          >
            <v-icon
              icon="mdi mdi-minus-thick"
              :size="14"
              class="block"
              :class="{
                'opacity-50 cursor-not-allowed':
                  addonAttributeSelected[attribute.id].quantity <= 0
              }"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useToast } from 'vue-toastification'
import type { CartItem, Addons } from '~/composables/useMenuModel'
const props = defineProps<{
  cartItem: CartItem
}>()
watch(
  () => props.cartItem.addons,
  () => fillAddonAttribue()
)
const toast = useToast()
const loading = ref<Record<string, boolean|undefined>>({})
const store = useCartStore()
const addonAttributeSelected = ref<Record<string, Addons>>({})
const { t } = useI18n()

const fillAddonAttribue = () => {
  addonAttributeSelected.value = {}

  props.cartItem.material.addons?.forEach((attribute) => {
    addonAttributeSelected.value[attribute.id] = {
      quantity: 0,
      ...attribute
    }
  })

  props.cartItem.addons?.forEach((attribute) => {
    addonAttributeSelected.value[attribute.id].quantity =
      attribute.pivot?.quantity || 0
  })
}

const editQuantityAddonAttribue = async (
  attributeId: string,
  operation: string
) => {
  if (!loading.value[attributeId]) {
    loading.value[attributeId] = true

    const modified = await store.editAddonAttribute(
      props.cartItem.material,
      attributeId,
      props.cartItem.id,
      operation
    )

    if (!modified) {
      toast.error(t('update_failed'), { timeout: 1000 })
    }

    loading.value[attributeId] = false
  }
}

function disableIncreaseItem(attributeId: string) {
  return (
    addonAttributeSelected.value[attributeId].quantity ===
    addonAttributeSelected.value[attributeId].max_amount
  )
}

fillAddonAttribue()
</script>
