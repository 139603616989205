<template>
  <div
    v-if="availableShippingWays.length"
    class="rounded-dotted mt-1 text-text_color text-opacity-80"
  >
    <div class="flex md:justify-around justify-between items-center">
      <div class="md:font-semibold md:text-lg">{{ t('delivery_methods') }}</div>
      <div class="mx-2 mt-1">
        <div
          v-for="(item, index) in availableShippingWays"
          :key="`availableShippingWays-${index}`"
        >
          <div
            class="flex cursor-pointer rounded-dotted light mb-1"
            @click="updateMethodShipping(item)"
          >
            <div class="w-2"></div>
            <input
              v-model="updateShippingWays"
              :disabled="item.disable"
              type="radio"
              :value="item"
            />
            <div class="w-4"></div>
            <div class="mx-1">{{ item.title }}</div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="
        !store.isStoreSupportsPrivateDeliveryWithPrice &&
          modelValue?.type == 'store_delivering_way'
      "
      class="text-center bg-bg_color brightness-95 font-semibold text-sm p-1 border-t border-text_alt_color rounded-lg mt-6"
    >
      {{ props.store.shipping_details }}
    </div>
    <CartCostCartPerStoreAgentPlaces
      v-if="
        isAuthenticated &&
          modelValue?.title &&
          modelValue?.title !== t('store_pickup')
      "
      :selected-agent-place="modelValue?.agentPlace"
      :agent-places="agentPlaces"
      @select-agent-place="updateAgentPlace($event)"
    />
    <div
      v-if="enableExternalShipping"
      class="text-[11px] text-center leading-5 mt-3 bg-bg_color rounded-lg"
    >
      {{ t('time_shipping_will_be_determined_later') }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { useToast } from 'vue-toastification'
import type { ShippingWay, AgentPlace } from '~/composables/useMenuModel'
const { t } = useI18n()
const toast = useToast()
const { isAuthLoggedIn } = useMenuModel()
const isAuthenticated = isAuthLoggedIn()
const props = defineProps<{
  modelValue?: ShippingWay
  branchId?: number
  store: Store
  costItemsStore: {
    originalPrice: number
    discount: number
  }
  agentPlaces: AgentPlace[] | null
}>()

const shippingSela = ref<
  | {
      cost: number
      time: number
    }
  | undefined
>(undefined)
const isCartShouldBeShipped = ref<boolean>(false)
const isStoreSupportsSilaDelivery = computed(() => {
  return (
    (isDomain && props.store.supports_sila_delivery_for_provider) ||
    (!isDomain && props.store.supports_sila_delivery_for_admin)
  )
})
const isDomain = useIsDomain()
async function fetchShippingSela() {
  if (isStoreSupportsSilaDelivery.value && !isDomain) {
    try {
      const data = await $fetch<
        ApiResponse<{
          cost: number
          time: number
        }>
      >(useBaseUrl(`/getSilaDeliveryCostAndTime/${props.store.id}`))
      if (data.success) {
        shippingSela.value = data.data
      }
    } catch (error) {}
  }
}

await fetchShippingSela()
const deliveryStorePrivateDetails = computed(() => {
  const minimumPriceNumber = parseFloat(
    props.store.minimum_price_for_zero_delivery_cost || '0'
  )
  let deliveryCostLocal = props.store.isStoreSupportsPrivateDeliveryWithPrice
    ? parseFloat(props.store.delivery_cost || '0')
    : undefined
  const totalPrice =
    props.costItemsStore.originalPrice - props.costItemsStore.discount
  if (
    totalPrice > minimumPriceNumber &&
    props.store.isStoreSupportsPrivateDeliveryWithPrice
  ) {
    deliveryCostLocal = 0
  }
  return {
    deliveryCost: deliveryCostLocal,
    agentPlace: props.modelValue?.agentPlace,
    disable: enableExternalShipping.value,
    type: 'store_delivering_way',
    deliveryTime: props.store.delivery_duration || 0,
    title: t('store_delivering_way', {
      name: props.store.name
    })
  }
})

const updateShippingWays = computed({
  get() {
    return props.modelValue
  },

  set(value) {
    updateMethodShipping(value)
  }
})
function updateMethodShipping(value: ShippingWay | undefined) {
  if (value?.disable) {
    toast.info(t('shipping_method_cannot_be_selected'), { timeout: 1500 })
  } else {
    return emit('update:modelValue', value)
  }
}

const emit = defineEmits<{
  (event: 'update:modelValue', value?: ShippingWay): void
}>()
const availableShippingWays = computed(() => {
  const availableShippingWaysTemp = ref<ShippingWay[]>([])
  if (shippingSela.value) {
    availableShippingWaysTemp.value.push({
      deliveryCost: shippingSela.value.cost,
      deliveryTime: shippingSela.value.time,
      title: t('delivery_by_sela'),
      type: 'delivery_by_sela',
      disable: enableExternalShipping.value,
      agentPlace: props.modelValue?.agentPlace
    })
  }
  if (props.store.support_delivery && isDomain) {
    availableShippingWaysTemp.value.push(deliveryStorePrivateDetails.value)
  }
  if (isDomain) {
    availableShippingWaysTemp.value.push({
      deliveryCost: undefined,
      deliveryTime: undefined,
      type: 'store_pickup',
      title: t('store_pickup'),
      agentPlace: undefined
    })
  }

  if (
    isCartShouldBeShipped.value &&
    props.modelValue?.type === 'external_shipping'
  ) {
    availableShippingWaysTemp.value.push(externalShipping.value)
  }
  return availableShippingWaysTemp.value
})
const enableExternalShipping = computed(() => {
  return (
    isCartShouldBeShipped.value &&
    props.modelValue?.type === 'external_shipping'
  )
})
const externalShipping = computed(() => {
  return {
    deliveryCost: undefined,
    deliveryTime: undefined,
    type: 'external_shipping',
    title: t('external_shipping'),
    agentPlace: props.modelValue?.agentPlace
  }
})
onMounted(() => {
  if (availableShippingWays.value?.length) {
    const ShippingWayTemp = availableShippingWays.value[0]
    emit('update:modelValue', ShippingWayTemp)
  }
})
async function updateAgentPlace(agentPlace: AgentPlace) {
  if (agentPlace.id !== props.modelValue?.agentPlace?.id) {
    isCartShouldBeShipped.value = await checkIfIsCartShouldBeShipped(agentPlace)
    if (isCartShouldBeShipped.value) {
      const ShippingWayTemp = externalShipping.value
      ShippingWayTemp!.agentPlace = agentPlace
      updateMethodShipping(ShippingWayTemp)
    } else {
      const ShippingWayTemp = props.modelValue
      ShippingWayTemp!.agentPlace = agentPlace
      updateMethodShipping(ShippingWayTemp)
    }
  }
}

async function checkIfIsCartShouldBeShipped(agentPlace: AgentPlace) {
  const { data, error } = await useNuxtApiFetch<ApiResponse<boolean>>(
    '/user/cart/getIsCartShouldBeShipped',
    {
      method: 'GET',
      query: {
        storeId: props.store.id,
        branchId: props.branchId,
        agentPlaceId: agentPlace.id
      }
    }
  )
  if (error.value) {
    return false
  } else {
    return data.value?.data || false
  }
}
</script>
