<template>
  <div
    v-if="availablePayWays.length"
    class="rounded-dotted text-text_color text-opacity-80"
  >
    <div class="flex justify-around items-center">
      <div class="md:font-semibold md:text-lg">{{ t('pay_ways') }}</div>
      <div class="mx-2 mt-1">
        <div
          v-for="(item, index) in availablePayWays"
          :key="`availablePayWays-${index}`"
        >
          <div
            class="flex cursor-pointer mb-1 rounded-dotted light"
            @click="updatePayTypeMethod(item)"
          >
            <div class="w-2"></div>
            <input v-model="updatePayType" type="radio" :value="item" />
            <div class="w-4"></div>
            <div class="mx-1">{{ item }}</div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="modelValue.payType == t('pay_by_transfer')"
      class="flex justify-center items-center flex-wrap mt-1"
    >
      <div
        v-for="(item, index) in payWaysCard"
        :key="`payWaysCard-${index}`"
        :class="
          modelValue.payWayCard == item
            ? 'z-10 ring-2 rounded-md ring-red-600 bg-red-200 text-black '
            : 'bg-red-50 text-gray-900'
        "
        class="flex my-1 mx-0.5 cursor-pointer"
        @click="updatePay(modelValue.payType, item)"
      >
        <div
          class="p-1 overflow-hidden text-sm w-20 h-20 font-medium border border-red-900 rounded-md hover:bg-red-100"
        >
          <div
            v-if="getPathImagePayCard(item.name)"
            class="aspect-[1] flex justify-center items-center"
          >
            <img
              v-if="getPathImagePayCard(item.name)"
              :src="getPathImagePayCard(item.name)"
              alt="item.name"
            />
          </div>
          <div v-else class="flex flex-col justify-center items-center">
            <img
              src="/images/CreditCard.webp"
              alt="item.name"
              width="60"
              class="m-auto"
              height="45"
            />
            <div class="text-center text-xs">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="modelValue.payWayCard?.name"
      class="flex flex-wrap justify-center font-medium items-center text-sm mt-2"
    >
      <div class="text-center">
        {{
          t('transfer_following_account', {
            price: totalAmount
          })
        }}
      </div>
      <div class="flex items-center mx-1 rounded-lg">
        <div class="font-bold">
          {{ modelValue.payWayCard?.name }}
        </div>
        <v-icon
          icon="mdi mdi-content-copy"
          :size="13"
          class="cursor-pointer mx-1 color-icon"
          @click="copyToClipBoard(modelValue.payWayCard?.name ?? '')"
        />
      </div>
      <div class="flex items-center">
        <div class="mx-1">
          {{ t('to_account') }}
        </div>
        <div class="font-bold">
          {{ modelValue.payWayCard?.account_owner_name }}
        </div>
        <v-icon
          icon="mdi mdi-content-copy"
          :size="13"
          class="cursor-pointer mx-1 color-icon"
          @click="copyToClipBoard(modelValue.payWayCard?.account_owner_name ?? '')"
        />
      </div>
      <div class="flex items-center">
        <div class="mx-1">
          {{ t('bearing_the_number') }}
        </div>
        <div class="font-bold">
          {{ modelValue.payWayCard?.iban }}
        </div>
        <v-icon
          icon="mdi mdi-content-copy"
          :size="13"
          class="cursor-pointer mx-1 color-icon"
          @click="copyToClipBoard(modelValue.payWayCard?.iban ?? '')"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useToast as useToastlocal } from 'vue-toastification'
import type { payWay, Store } from '~/composables/useMenuModel'
const toast = useToastlocal()
const { t } = useI18n()
const props = defineProps<{
  modelValue: {
    payType?: String
    payWayCard?: payWay
  }
  store: Store
  shippingWay?: ShippingWay
  totalAmount: String
}>()
const updatePayType = computed({
  get() {
    return props.modelValue.payType
  },

  set(value) {
    updatePayTypeMethod(value)
  }
})
function updatePayTypeMethod(value: String | undefined) {
  if (props.modelValue.payType !== value) {
    return updatePay(value)
  }
}
function copyToClipBoard(value: string) {
  navigator.clipboard.writeText(value)
  toast.info(t('Copied_to_clipboard'), { timeout: 1000 })
}

onMounted(() => {
  intialValues()
})
watch(
  () => props.shippingWay,
  () => {
    if (props.shippingWay?.type === 'external_shipping') {
      intialValues()
    }
  }
)
function intialValues() {
  if (availablePayWays.value.length === 1) {
    updatePay(availablePayWays.value[0])
    if (
      availablePayWays.value[0] === t('pay_by_transfer') &&
      payWaysCard.value.length === 1
    ) {
      updatePay(availablePayWays.value[0], payWaysCard.value[0])
    }
  }
}
function updatePay(payType?: String, payWayCard?: payWay) {
  emit('update:modelValue', {
    payType,
    payWayCard
  })
}
const emit = defineEmits<{
  (
    event: 'update:modelValue',
    value: {
      payType?: String
      payWayCard?: payWay
    }
  ): void
}>()
const payWaysCard = computed(() => {
  if (props.store?.pay_ways?.length) {
    return props.store?.pay_ways as payWay[]
  }
  return [] as payWay[]
})

function getPathImagePayCard(value: string) {
  if (value === 'الأمانة') {
    return '/images/amana.webp'
  }
  if (value === 'ثقة') {
    return '/images/theqa.webp'
  }
  if (value === 'سهل') {
    return '/images/sahel.webp'
  }
  if (value === 'شامنا') {
    return '/images/shamona.webp'
  }
  if (value === 'صلة') {
    return '/images/sela_pay.webp'
  }
  return undefined
}

const availablePayWays = computed(() => {
  const availablePayWaysTemp = [] as String[]
  if (props.store?.pay_on_door) {
    availablePayWaysTemp.push(t('pay_on_door'))
  }
  if (
    props.store?.pay_by_transfer &&
    payWaysCard.value.length &&
    props.shippingWay?.type !== 'external_shipping'
  ) {
    availablePayWaysTemp.push(t('pay_by_transfer'))
  }
  return availablePayWaysTemp
})
</script>
<style>
.color-icon {
  @apply text-nav_color !important;
}
</style>
