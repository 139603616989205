<template>
  <div class="rounded-lg my-1 relative text-text_color">
    <!-- text-nav_color -->
    <div
      class="flex bg-bg_color brightness-95 flex-wrap border pt-2 border-text-200 rounded-t-lg justify-between pb-2"
    >
      <div class="mx-0.5 md:mx-2 font-medium">
        {{
          t('offer_with', {
            number: offerItems[0]?.material_group_offer?.offer_number
          })
        }}
      </div>

      <div
        v-if="offerItems[0].material.store?.price_visible"
        class="mx-0.5 md:mx-2 font-medium"
      >
        {{
          t('price_of_one_offer', {
            price: offerItems[0]?.material_group_offer?.price
          })
        }}
      </div>
    </div>
    <CartNormalItemMaterialCard
      v-for="item in offerItems"
      :key="`cart-item-${item.material.id}`"
      :is-has-offer-group="true"
      class="py-0.5 border-t bg-bg_color"
      :cart-item="item"
      @loading="loading = $event"
    />

    <div
      class="flex items-center bg-bg_color brightness-95 justify-between px-3 py-2 text-xs sm:text-sm border-t border-text_alt_color"
    >
      <div class="flex">
        <button
          class="w-max"
          :class="{ 'bg-nav text-nav_color': !isDomain}"
          :aria-label="t('delete_item', { name: t('item') })"
          @click="removeOffer(offerItems)"
        >
          <div class="flex items-center">
            <v-icon
              icon="mdi mdi-trash-can-outline"
              class="text-sm"
              :size="20"
            />
            <div class="px-1">
              {{ t('delete_item', { name: t('offer') }) }}
            </div>
          </div>
        </button>
        <SelaLoadingIndicator
          v-if="loading"
          :loading="true"
          color="border-nav_color"
          class="text-nav_color h-full"
        />
      </div>
      <div class="flex">
        <div class="font-semibold">{{ t('full_price') }}:</div>
        <div>
          {{ fullOfferPrice }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useToast } from 'vue-toastification'
import type { CartItem } from '~~/composables/useMenuModel'
const props = defineProps<{
  offerItems: CartItem[]
  offerNumber: any
  branchId?: number
}>()
const fullOfferPrice = computed(() => {
  const offerPrice = props.offerItems[0].material_group_offer?.priceNumber ?? 0
  const addonsPrice = props.offerItems.reduce((total, item) => {
    return total + item.addonPrice!
  }, 0)
  return useStringPrice(
    offerPrice + addonsPrice,
    props.offerItems[0]?.material_group_offer?.currencyCode ?? ''
  )
})
const toast = useToast()
const { deleteItem } = useCartStore()
const loading = ref(false)
const { t } = useI18n()

async function removeOffer(items: CartItem[]) {
  if (!loading.value) {
    loading.value = true
    const isDelete = await deleteItem(items[0].id!, {
      material_group_offer_id: items[0].material_group_offer_id!,
      group_offer_uuid: items[0].group_offer_uuid!,
      store_id: items[0].material.store_id
    })
    if (isDelete) {
      toast.success(t('delete_done'), { timeout: 1000 })
    }

    loading.value = false
  }
}
const isDomain = useIsDomain()
</script>
