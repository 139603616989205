<template>
  <div v-if="!isDomain || (isDomain && store?.cart_visible)">
    <div v-if="mounted" class="min-h-screen sela-container">
      <ClientOnly>
        <CartItemsCart :agent-places="getPlaces" />
      </ClientOnly>
    </div>
  </div>

  <div v-else class="flex flex-col items-center justify-center mt-20">
    <IconsEmptyCart class="w-20 h-20 sm:w-28 h-28" />

    <p class="mt-10 mb-5">{{ t('not_support_cart') }}</p>
  </div>
</template>

<script setup lang="ts">
const mounted = ref<boolean>(false)
const { store } = useDomainState()
const { getPlaces, fetchAgentPlace } = useAgentPlaces()
const isDomain = useIsDomain()
if (!isDomain || (isDomain && store.value?.cart_visible)) {
  await fetchAgentPlace()
  await useCartStore()
    .serverLoad()
    .finally(() => {
      mounted.value = true
    })
}

const { url } = useDomainHost()
const { t } = useI18n()

useServerSeoMeta(
  useOgMeta(
    url + '/cart',
    t('cart'),
    t('sila_description'),
    url + '/images/sela.webp'
  )
)

useHead({
  title: t('cart'),
  link: [useSeoCanonical(url + '/cart')],
  script: [
    useSeoBreadcrumb([
      useSeoBreadcrumbItem(1, t('home'), url),
      useSeoBreadcrumbItem(2, t('cart'), url + '/cart')
    ])
  ]
})
</script>
