<template>
  <div>
    <div
      v-if="agentPlaces?.length"
      class="flex justify-center items-stretch flex-wrap mt-3"
    >
      <div
        v-for="(item, index) in agentPlaces"
        :key="`agentPlaces-${index}`"
        :class="
          selectedAgentPlace?.id == item.id
            ? ' rounded-md ring-red-600 bg-red-200 text-black border-2'
            : 'bg-red-50 text-gray-900'
        "
        class="p-0.5 cursor-pointer  w-20 border-red-900 rounded-md hover:bg-red-100 m-0.5"
        @click="emit('select-agent-place', item)"
      >
        <div
          class="h-full text-center"
        >
          <img
            src="/images/cartLocation.webp"
            alt="item.name"
            width="40"
            class="m-auto my-0.5"
          />
          <div v-if="item.text_address" class="text-xs">{{ t(item.text_address) }}</div>
          <div v-if="item.city?.name" class="text-xs mb-0.5">{{ item.city?.name }}</div>
          <div v-if="item.details" class="text-[10px] leading-3">{{ item.details }}</div>
        </div>
      </div>
    </div>
    <div v-else>
      <div
        class="flex justify-center items-start mt-3"
      >
        <v-icon
          icon="mdi mdi-information"
          :size="15"
          class="mx-1 flex-shrink-0"
        />
        <div class="break-all text-xs"> {{ t('no_addresses_currently_saved') }} </div>
      </div>
    </div>
    <SelaDialouge width="auto">
      <template #activator="{ props: propsTemp }">
        <div
          class="mt-2 flex justify-center cursor-pointer items-start text-black bg-red-100 w-max mx-auto p-1"
        >
          <v-icon
            icon="mdi mdi-map-marker-circle"
            :size="15"
            class="mx-1 flex-shrink-0"
          />
          <div
            class="break-all text-xs"
            v-bind="propsTemp"
          >
            {{ t('create_address') }}
          </div>
        </div>
      </template>
      <template #content="{ close}">
        <div class="h-[75vh]">
          <v-locale-provider :rtl="useIsRtl()" class="w-full">
            <MyAccountPlacesCreateForm class="bg-bg_alt_color p-2 mb-2" @created="createNewAddress($event,close)" />
          </v-locale-provider>
        </div>
      </template>
    </SelaDialouge>
  </div>
</template>

<script setup lang="ts">
import type { AgentPlace } from '~/composables/useMenuModel'

const props = defineProps<{
  selectedAgentPlace?: AgentPlace
  agentPlaces: AgentPlace[] | null
}>()

const emit = defineEmits(['select-agent-place', 'add-agent-place'])

const { t } = useI18n()

function createNewAddress(event:any, close:any) {
  emit('select-agent-place', event)
  close()
}
onMounted(() => {
  if (props.agentPlaces?.length === 1) {
    emit('select-agent-place', props.agentPlaces[0])
  }
})

</script>
